<template>
  <div class="list-pagination">
    <a href="javascript:;" @click="
        nowPage -= 1;
        getList();
      " v-if="nowPage > 1">上一页</a>
    <a href="javascript:;" @click="
        nowPage = 1;
        getList();
      ">
      << </a>
        <a href="javascript:;" @click="
        nowPage = v;
        getList();
      " :class="{ active: v == nowPage }" v-for="(v, index) in pageList" :key="index">{{ v }}</a>
        <a href="javascript:;" @click="
        nowPage = allPage;
        getList();
      ">>></a>
        <a href="javascript:;" @click="
        nowPage += 1;
        getList();
      " v-if="nowPage < allPage">下一页</a>
        <span>到第
          <input @change="getList(true)" oninput="value=value.replace(/[^\d]/g,'')" v-model="nowPage1" />
          页</span>
  </div>
</template>

<script>
  export default {
    name: "commonPagination",
    props: {
      nowPage: {
        default: 1
      },
      pageSize: {
        default: 5
      },
      allCount: {
        default: 0
      }
    },
    data() {
      return {
        nowPage1: 1
      };
    },
    methods: {
      getList(btn_page) {
        if (btn_page) {
          this.nowPage1 = Math.min(this.allPage, Math.max(this.nowPage1, 1));
          this.$emit("getList", this.nowPage1, this.pageSize);
        } else {
          this.$emit("getList", this.nowPage, this.pageSize);
        }
      }
    },
    computed: {
      allPage() {
        if (this.allCount === 0) {
          return 1;
        }
        let res = Math.floor(this.allCount / this.pageSize);
        if (this.allCount % this.pageSize === 0) {
          return res;
        } else {
          return res + 1;
        }
      },
      pageList() {
        if ((this.nowPage < 4 && this.allPage > 5) || this.allPage <= 5) {
          return Math.min(this.allPage, 5);
        } else {
          const list = [];
          for (
            let i = this.nowPage - 2; i <= Math.min(this.nowPage + 2, this.allPage); i++
          ) {
            list.push(i);
          }
          return list;
        }
      }
    }
  };
</script>

<style scoped>
  /* pagination */
  .list-pagination {
    text-align: center;
    height: 50px;
    margin-top: 20px;
  }

  .list-pagination a {
    min-width: 28px;
    height: 30px;
    border: 1px solid #dfe1e2;
    padding: 5px 7px;
    font-weight: 500;
    background-color: #fff;
    color: #2174d1;
    margin: 0 3px;
    display: inline-block;
    text-align: center;
  }

  .list-pagination a:hover {
    color: #ffffff;
    background-color: #0d5799;
  }

  .list-pagination a.active {
    background-color: #0d5799;
    color: #fff;
  }

  .list-pagination span {
    color: #696969;
  }

  .list-pagination input {
    border: 1px solid #dfe1e2;
    width: 30px;
    height: 32px;
    text-align: center;
  }
</style>
